import React, { FC } from "react";
import styled, { css } from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledItem = styled.div<{ $featured: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.washLighter};
  font-weight: 500;

  :last-child {
    border-bottom: none;
  }

  ${({ $featured }) =>
    $featured &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`;

const StyledCounter = styled.span`
  margin-right: 15px;
`;

const StyledName = styled.div`
  margin-right: 20px;
`;

const StyledContent = styled.div`
  margin-left: auto;
`;

const Table: FC<Props> = ({ values, withCount, className }) => (
  <StyledWrapper className={className}>
    {values.map(({ name, content, featured }, index) => (
      <StyledItem key={index} $featured={featured}>
        {withCount && (
          <StyledCounter>
            {index <= 8 ? `0${index + 1}` : index + 1}
          </StyledCounter>
        )}
        <StyledName>{name}</StyledName>
        <StyledContent
          dangerouslySetInnerHTML={{
            __html: typeof content === "string" ? content : content.toString(),
          }}
        />
      </StyledItem>
    ))}
  </StyledWrapper>
);

interface Props {
  values: { name: string; content: string | number; featured?: boolean }[];
  withCount?: boolean;
  className?: string;
}

export default Table;
