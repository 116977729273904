import React, { FC, useState } from "react";
import BasicTemplate from "@templates/BasicTemplate";
import styled, { css } from "styled-components";
import Button from "@components/atoms/Button/Button";
import { graphql, Link, useStaticQuery } from "gatsby";
import { arrowLeftIcon, arrowRightIcon, downloadIcon } from "@assets/icons";
import { Icon } from "@iconify/react";
import { cssEaseOutExpo } from "@theme/easing";
import InteractiveVisualization from "@components/molecules/InteractiveVisualization/InteractiveVisualization";
import Contact from "@components/molecules/Contact/Contact";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Switch from "@components/atoms/Switch/Switch";
import Legend from "@components/atoms/Legend/Legend";
import Table from "@components/atoms/Table/Table";
import { House, house } from "@rendpro/core-ui";
import { formatNumber } from "@utils/formatNumber";
import { getDate } from "@utils/getDate";
import { formatPrice } from "@utils/formatPrice";
import { transformRooms } from "@utils/transformRooms";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import houses from "../data/houses.json";
import { ChildImageSharp } from "../types/queryTypes";

const StyledWrapper = styled.div`
  width: 90%;
  max-width: 1600px;
  margin: 175px auto 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    max-width: 700px;
  }

  @media (max-width: 720px) {
    margin: 120px auto 0;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledBackButton = styled(Button)`
  svg {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const StyledDownloadLink = styled.a`
  display: flex;
  align-items: center;
  padding: 13px 18px;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  transition: color 0.5s ${cssEaseOutExpo}, background 0.5s ${cssEaseOutExpo};

  svg {
    color: ${({ theme }) => theme.primary};
    margin-right: 18px;
    font-size: 2.2rem;
    transition: color 0.5s ${cssEaseOutExpo};
  }

  :hover {
    background: ${({ theme }) => theme.primary};
    color: #fff;

    svg {
      color: #fff;
    }
  }

  :active {
    transform: scale(0.9);
  }
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding-bottom: 550px;
    margin-top: 60px;
  }
`;

const StyledLeftColumn = styled.div`
  flex: 1;
  margin-top: 40px;
`;

const StyledImagesWrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  display: grid;
`;

const StyledImageWrapper = styled.div<{ $isActive: boolean }>`
  width: 100%;
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ${cssEaseOutExpo};

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`;

const StyledRightColumn = styled.div`
  width: 480px;
  margin-left: 80px;

  @media (max-width: 1240px) {
    width: 340px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
`;

const StyledHeadline = styled.h1`
  margin: 24px 0 45px;
  font-size: 7.2rem;
  font-family: "Cardo", serif;
  font-weight: 400;
  overflow: hidden;
`;

const StyledTable = styled(Table)`
  @media (max-width: 1024px) {
    ${({ withCount }) =>
      withCount &&
      css`
        position: absolute;
        bottom: 0;
      `}
  }
`;

const StyledContactButton = styled(ScrollTo)`
  margin: 55px 0;
  background: ${({ theme }) => theme.primary};
  padding: 32px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  border: 0;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.5s background ${cssEaseOutExpo};
  text-decoration: none;

  svg {
    font-size: 2.2rem;
  }

  :hover {
    background: ${({ theme }) => theme.primaryDarker};
  }

  :active {
    transform: scale(0.95);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const HouseTemplate: FC<Props> = ({ data, pageContext }) => {
  const [visType, setVisType] = useState<0 | 1>(1);
  const { datoCmsHouse, vis2d, vis3dA, vis3dB } = data;
  const {
    number,
    availability,
    price,
    area,
    parcelArea,
    garage,
    deliveryDate,
    roomsCount,
    rooms,
  } = datoCmsHouse;

  return (
    <BasicTemplate isAnotherThanIndex title={`Lokal ${number} | `}>
      <StyledWrapper data-scroll="" data-scroll-start="top top+=160px">
        <StyledHeader>
          <StyledBackButton
            forwardedAs={Link}
            to="/"
            icon={arrowLeftIcon}
            iconPosition="left"
            state={{
              scrollTo: "#interactive_visualization",
            }}
          >
            Powrót
          </StyledBackButton>
          <StyledDownloadLink href={`/lokal_${number}.pdf`}>
            <Icon icon={downloadIcon} />
            Pobierz kartę PDF
          </StyledDownloadLink>
        </StyledHeader>
        <StyledInnerWrapper data-scroll="" data-scroll-start="top 20%">
          <StyledLeftColumn>
            <Switch
              firstText="Rzut 2D"
              secondText="Wizualizacja"
              currentValue={visType}
              setCurrentValue={setVisType}
            />
            <StyledImagesWrapper>
              <StyledImageWrapper $isActive={visType === 1}>
                <StyledImage
                  image={getImage(
                    pageContext.houseNumber.toUpperCase().includes("B")
                      ? vis3dB.childImageSharp
                      : vis3dA.childImageSharp
                  )}
                  alt=""
                />
              </StyledImageWrapper>
              <StyledImageWrapper $isActive={visType === 0}>
                <StyledImage image={getImage(vis2d.childImageSharp)} alt="" />
              </StyledImageWrapper>
            </StyledImagesWrapper>
          </StyledLeftColumn>
          <StyledRightColumn>
            <Legend availability={availability} />
            <StyledHeadline>Lokal {number}</StyledHeadline>
            <StyledTable
              values={[
                {
                  name: "Powierzchnia",
                  content: `${formatNumber(area)} m<sup>2</sup>`,
                },
                {
                  name: "Powierzchnia działki",
                  content: `${parcelArea} m<sup>2</sup>`,
                },
                {
                  name: "Ilość pomieszczeń",
                  content: roomsCount,
                },
                {
                  name: "Garaż",
                  content: garage,
                },
                {
                  name: "Termin oddania",
                  content:
                    typeof deliveryDate === "number"
                      ? getDate(deliveryDate)
                      : deliveryDate,
                },
                {
                  name: "Cena",
                  content: formatPrice(price),
                  featured: true,
                },
              ]}
            />
            <StyledContactButton href="#contact">
              Skontaktuj się z nami <Icon icon={arrowRightIcon} />
            </StyledContactButton>
            <StyledTable values={transformRooms(rooms)} withCount />
          </StyledRightColumn>
        </StyledInnerWrapper>
      </StyledWrapper>
      <InteractiveVisualization />
      <Contact />
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($houseNumber: String!) {
    datoCmsHouse(number: { eq: $houseNumber }) {
      number
      area
      parcelArea
      availability
      deliveryDate
      garage
      price
      roomsCount
      rooms {
        name
        area
      }
    }

    vis3dA: file(name: { eq: "vis_3d_a" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
        )
      }
    }

    vis3dB: file(name: { eq: "vis_3d_b" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
        )
      }
    }

    vis2d: file(name: { eq: "vis_2d" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

interface Props {
  pageContext: {
    houseNumber: string;
  };

  data: {
    vis2d: ChildImageSharp;
    vis3dA: ChildImageSharp;
    vis3dB: ChildImageSharp;
    datoCmsHouse: House;
  };
}

export default HouseTemplate;
